@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}
html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background-color: var(--bg);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}


img{
    width: 100%;
}


ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
    
}

*::-moz-selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--themeColor) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--themeColor);
    color: #fff;
    text-shadow: none
}


.textGrey{
    color: var(--textGrey);
}
.white{
    color: var(--white);
}
.darkBlue{
    color: var(--darkBlue);
}
.themeColor{
    color: var(--themeColor);
}
.gradientBlue{
        background: var(--gradientBlue);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
}
.gradientGrey{
        background: var(--gradientGrey);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
}
.gradientGrey{
    color: var(--gradientGrey);
}

.bg-textGrey{
    background: var(--textGrey);
}
.bg-white{
    background: var(--white);
}
.bg-darkBlue{
    background: var(--darkBlue);
}
.bg-themeColor{
    background: var(--themeColor);
}
.bg-gradientBlue{
    background: var(--gradientBlue);
}
.bg-gradientGrey{
    background: var(--gradientGrey);
}

.textarea-box {
    position: relative;
    box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    -webkit-box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    -moz-box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    border-radius: 20px;
}
.textarea-box > textarea {
    padding: 10px 18px 10px 18px;
    border-radius: 20px;
    background: var(--white);
    height: 105px;
    outline: none;
    border: none;
    width: 100%;
    color: var(--textGrey) !important;
}


.sticky{
    background: var(--themeColor);
    box-shadow: 0px 0px 3px (--themeColor);
}

.common-space{
    position: relative;
    padding: 55px 0;
}


.banner-bg{
    width:100%;
    min-height: 600px;
    height:100vh;
    background: url('../images/png/banner-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    text-transform: uppercase;
}
header{
    .headerWhatsapp{
        button{
            span{
                padding: 0 !important;
                font-family: var(--regular) !important;
            }
        }
        
    }
    .nav-bar-list {
        display: flex;
        align-items: center;
        gap: 35px;
    }
    .toggleBtn {
        display: none;
    }
    .logoHeader{
        cursor: pointer;
    }
}
header.sticky{
    .headerWhatsapp{
        button:hover{
            border: 1px solid var(--white) !important;
        }
    }
}


.main-heading{
    position: relative;
    z-index: 2;
    color: var(--darkGrey);
    display: inline-block;
}
.main-heading > .main-text::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 45px;
    height: 2px;
    background: var(--themeColor);
    bottom: 0px;
}

.tStripe::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 43px;
    height: 2px;
    background: var(--themeColor); 
}
.aboutFluid{
    padding-top: 80px;
}
.about-card-text{
    padding: 70px 100px;
}
.about-card-img{
    position: absolute;
    top: -35px;
    right: 130px;
}

.tabNavBtn:hover{
    color: var(--themeColor);
    
}
.tabNavBtn:hover::before {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 3px;
    background: var(--themeColor);

}
.ticketFluid{
    padding-left: 0;
    padding-right: 0;
    padding-top: 65px;
    padding-bottom: 0px;
    & > .container{
        padding-left: 0;
    padding-right: 0;
    }
}
.ticketWorld{
    position: absolute;
    top: -90px;
    width: auto;
    right: 90px;
    z-index: -1;
}
.ticketContainer{
    position: relative;
    background-image: url(../images/png/ticketBgEn.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 435px;
    padding-top: 100px;
    padding-left: 40px;
    display: flex;
    .ticket-text-card{
        width: 890px;
        padding-right: 30px;
        .ticketDetailWrapper{
            display: flex;
            width: 100%;
            gap: 15px;
            margin-top: 30px;
            .ticketDetailCol{
                display: flex;
                // width: 100%;
                flex-wrap: wrap;
                *{
                    width: 100%;
                    color: var(--white);
                    white-space: nowrap;
                }
                .txtFromTo{
                    font-family: var(--medium);
                    font-size: 17px;
                }
                .txtDest{
                    font-family: var(--bold);
                    font-size: 19px;
                }
                .txtCont{
                    font-family: var(--medium);
                    font-size: 15px;
                }
                .txtDate{
                    font-family: var(--regular);
                    font-size: 14px;
                }
            }
            .ticketDetailDesCol{
                width: 100%;
                display: flex;
                align-items: center;
            }
            
        }
        .abtUserWrapper{
            display: flex;
            margin-top: 50px;
            gap: 30px;
            .abtUserCol{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                .abtUserTitle, .abtUserTxt{
                    color: var(--white);
                    width: 100%;
                    white-space: nowrap;
                }    
                .abtUserTitle{
                    font-family: var(--regular);
                    font-size: 15px;
                }
                .abtUserTxt{
                    font-family: var(--semiBold);
                    font-size: 17px;
                }
            }
            
        }
    }
    .boardingWrapper{
        .boardingContainer{
            padding: 30px 40px 0 40px;
            display: flex;
            flex-wrap: wrap;
            width: 320px;
            button{
                margin-top: 20px;
            }
        }
        
    }
    
}

.servicesFluid{
    padding-top: 20px;
    padding-bottom: 30px;
    .offersRow{
        margin-top: 50px;
        align-items: center;
    }
    .offerBanner{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top 0 left 0;
        border-radius: 20px;
        overflow: hidden;
        height: 226px;
        margin: 10px 0 10px 0;
        width: 100%;
        float: left;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 16px -11px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 10px 16px -11px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 10px 16px -11px rgba(0,0,0,0.75);
        .offerContentContainer{
            max-width: 320px;
            
        }
        .offerHeading{
            font-family: var(--semiBold);
            font-size: 25px;
            line-height: 28px;
            margin-bottom: 5px;
            color: var(--themeColor);
        }
        .offerText{
            font-family: var(--semiBold);
            font-size: 18px;
            line-height: 20px;
            color: var(--darkGrey);
            margin-bottom: 15px;
        }
    }
    .offerBanner1{
        background-image: url(../images/png/offerBanner1.png);
        padding-left: 40px;
    }
    .offerBanner2{
        background-image: url(../images/png/offerBanner2.png);
        padding-left: 300px;
        .offerHeading{
            color: var(--themeColor);
        }
        .offerText{
            color: var(--white);
        }
    }
    .planRow{
        padding: 0 70px 0 0px;
        .planCol2{
            padding-top: 20px;
        }
        .planLondon{
            background-image: url(../images/png/offer1.png);
        }
        .planMalaysia{
            background-image: url(../images/png/offer2.png);
        }
        .planItaly{
            background-image: url(../images/png/offer3.png);
        }
        .planDubai{
            background-image: url(../images/png/offer4.png);
        }
        .planMaldives{
            background-image: url(../images/png/offer5.png);
        }
        .planFrance{
            background-image: url(../images/png/offer6.png);
        }
        
    }
       

}

.whyChooseFluid{
    & > .container{
        padding-left: 0;
        padding-right: 0;
    }
}
.whyChooseWrapper{
    padding: 40px 80px 150px 80px;
    background-image: url(../images/png/whyChooseBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    .whyChooseTitle, .whyChooseText{
        display: flex;
        width: 100%;
        justify-content: center;
        text-align: center;
        color: var(--white);
    }
    .whyChooseTitle{
        font-size: 22px;
        font-family: var(--regular);
    }
    .whyChooseText{
        font-size: 30px;
        font-family: var(--semiBold);
        margin-top: 5px;
    }
    .whyChooseRow{
        position: relative;
        margin-top: 65px;
        z-index: 2;
    }
}

.expertiseFluid{
    padding-top: 40px;
    background-image: url(../images/png/clouds.png);
    background-repeat: no-repeat;
    background-position: top 0 left 50%;
    &::after{
        content: '';
        width: 100%;
        top: 30px;
        background: rgb(254,254,254);
        background: linear-gradient(0deg, rgba(254,254,254,1) 0%, rgba(244,245,250,1) 100%);
        position: absolute;
        z-index: -1;
        height: 300px;
        left: 0;
        right: 0;
    }
    &::before{
        content: '';
        width: 100%;
        top: 320px;
        bottom: 0;
        background: rgb(254,254,254);
        background: linear-gradient(0deg, rgba(244,245,250,1) 0%, rgba(254,254,254,1) 100%);
        position: absolute;
        z-index: -1;
    }
    .planeImg{
        position: relative;
        span{
            display: flex !important;
            width: 100%;
            justify-content: center;
            img{
                width: auto;
            }
        }
    }
    .journeyHeading{
        margin-top: 40px !important;
    }
    .journeyDesc{
        text-align: center;
        margin-top: 40px;
        padding: 0 40px 0 40px;
    }
    .service1{
        background-image: url(../images/png/service1.png);
    }
    .service2{
        background-image: url(../images/png/service2.png);
    }
    .service3{
        background-image: url(../images/png/service3.png);
    }
    .service4{
        background-image: url(../images/png/service4.png);
    }
    .service5{
        background-image: url(../images/png/service5.png);
    }
    .service6{
        background-image: url(../images/png/service6.png);
    }
    .service7{
        background-image: url(../images/png/service7.png);
    }
    .service8{
        background-image: url(../images/png/service8.png);
    }
    .service9{
        background-image: url(../images/png/service9.png);
    }
    .service10{
        background-image: url(../images/png/service10.png);
    }
    
    .serviceRow{
        margin-top: 60px;
    }
}


.contactFluid{
    padding-top: 0px;
    padding-bottom: 0;
    background-image: url(../images/png/map.png);
    background-repeat: no-repeat;
    background-position: top 0 left 50%;
    .contactDesc{
        text-align: center;
        margin-top: 40px;
        padding: 0 250px 0 250px;
    }
    .contactContainer{
        display: flex;
        width: 100%;
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 60px;
        border: 10px solid rgba(255, 255, 255, 0.5);
        background-clip: content-box;
        position: relative;
        z-index: 5;
        .contactRow{
            padding: 0 40px 0 40px;
        }
        .contactImg{
            position: absolute;
        }
        .contactImg1{
            right: 20px;
            top: -180px;
        }
        .contactImg2{
            left: 20px;
            top: -180px;
        }
    }
}

footer{
    background-image: url(../images/png/footerBg1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 0 left 50%;
    
    margin-top: -170px;
    .container{
        // height: 340px;
        padding: 200px 0 30px 0;
        .footer-list-container{
            .footer-list{
                a{
                    cursor: pointer;
                    
                }
            }
        }
    }
    .footerContentContainer{
        display: flex;
        align-items: center;
        gap: 40px;
    }
}
button span{
    padding-bottom: 3px;
}
.inputCol{
    position: relative;
}
.error-message{
    position: absolute;
    padding: 0px 0px 0 0px;
    margin: 0px 0;
    color: red !important;
    font-size: 13px !important;
    line-height: 20px;
    bottom: -12px;
}

/***********************RTL********************/
body.rtl {
    font-family: var(--arRegular) ;
    font-size: 16px;
    p{
        font-family: var(--arRegular) !important;
        font-size: 16px !important;
    }
    button{
        span{
            font-family: var(--arRegular) !important;
            font-size: 20px !important;
            padding-top: 3px;
        }
        svg{
            -webkit-transform: scaleX(-1) !important;
            -moz-transform: scaleX(-1) !important;
            -o-transform: scaleX(-1) !important;
            transform: scaleX(-1) !important;
        }
    }
    header{
        .headerWhatsapp{
            button{
                
                span{
                    padding: 0 !important;
                    font-family: var(--regular) !important;
                    font-size: 15px !important;
                    direction: ltr;
                }
            }
            
        }
    }
    .main-heading, .main-heading > *{
        font-family: var(--arBold)
    }
    .tStripe::before {
        right: 0;
        left: auto;
        top: -4px;
    }
    .ticketFluid{
        .ticketWorld {
            right: auto;
            left: 90px;
        }
        .ticketContainer{
            background-image: url(../images/png/ticketBg.png);
            padding-right: 40px;
            padding-left: 0px;
            .ticket-text-card {
                padding-left: 30px;
                padding-right: 0px;
                .ticketDetailWrapper{
                    .ticketDetailCol{
                        .txtFromTo, .txtDest, .txtCont, .txtDate {
                            font-family: var(--arBold);
                        }
                        .txtFromTo {
                            font-size: 18px;
                        }
                        .txtDest {
                            font-size: 22px;
                        }
                    } 
                    .ticketDetailDesCol{
                        -webkit-transform: scaleX(-1);
                        -moz-transform: scaleX(-1);
                        -o-transform: scaleX(-1);
                        transform: scaleX(-1);
                    }
                    .ticketFromDetailCol{
                        max-width: 230px;
                    }
                } 
                .abtUserWrapper{
                    .abtUserCol{
                        .abtUserTitle {
                            font-family: var(--arRegular);
                        }
                        .abtUserName{
                            font-family: var(--arBold);
                        }
                    } 
                } 
            }
            .boardingHeading{
                font-family: var(--arBold);
            }
            .getBPassDet{
                font-family: var(--arBold);
            }
        }
    }

    .servicesFluid{
        
        .offersRow{
            
        }
        .offerBanner{
            
            .offerContentContainer{
                
            }
            .offerHeading{
                font-family: var(--arBold);
            }
            .offerText{
                font-family: var(--arBold);
            }
        }
        
        .offerBanner1{
            padding-left: 0px;
            padding-right: 300px;
        }
        .offerBanner2{
            padding-left: auto;
            padding-right: 30px;
            .offerHeading{
            }
            .offerText{
            }
        }

        .planRow{
            padding: 0 0px 0 70px;
            .planContent{
                .planCity{
                    font-family: var(--arBold);
                }
                .planDays{
                    font-family: var(--arRegular);
                }
            }
            
            
        }
           
    
    }
    
    .whyChooseWrapper{
        .whyChooseTitle{
            font-size: 25px;
            font-family: var(--arRegular);
        }
    }

    .expertiseFluid{
        .servicesWrapper{
            .servicesContent{
                .servicesText{
                    font-family: var(--arBold);
                }
                
            }
        }
    }

    .contactFluid{
        
        .contactDesc{
            
        }
        .contactContainer{
            
            .contactRow{
                .contactFormWrapper{
                    label{
                        font-family: var(--arBold);
                    }
                }
            }
            .contactImg{
                
            }
            .contactImg1{
                
            }
            .contactImg2{
                
            }
        }
    }

    footer{
        
        .container{
            
            .footer-BG {
                right: auto;
                left: 0;
            }
            
            .footer-list-container{
                .footer-list{
                    a{
                        font-family: var(--arRegular);
                        
                    }
                }
            }
        }
        .copyRightDes{
            font-family: var(--arRegular);
            font-size: 14px;
        }
    }
    .error-message{
        font-size: 13px !important;
    }
    // .slick-slider[dir='ltr']  {
    //     float: left !important;
    //     direction: ltr !important;
    // }
    // .rtl [dir='ltr'] .slick-slide {
    //     float: left !important;
    //     direction: ltr !important;
    // }
    // /* Override RTL direction for Slick slider */
    // [dir='rtl'] .slick-slider.slick-initialized {
    //     direction: ltr !important;
    // }
    // [dir='rtl'] .slick-track{
    //     direction: ltr !important;
    // }
}




@import 'responsive';