@media (max-width: 1399px) {
    .ticketWorld{
        right: 75px;
    }
    .ticketContainer{
        padding-top: 80px;
        height: 375px;
        
        .ticket-text-card {
            padding-right: 10px;
            .ticketDetailWrapper .ticketDetailCol * {
                white-space: normal;
            }
            .ticketDetailWrapper {
                margin-top: 5px;
                gap: 10px;
                .ticketDetailCol{
                    .txtDest {
                        line-height: 25px;
                    }
                    .txtCont {
                        line-height: 17px;
                    }
                } 
                .ticketFromDetailCol {
                    max-width: 260px;
                }
                .ticketDetailDesCol {
                    max-width: 200px;
                }
            }
            .abtUserWrapper {
                margin-top: 25px;
            }
        }
        .boardingWrapper .boardingContainer {
            padding: 20px 40px 0 40px;
        }
    } 

    .servicesFluid{
        
        .planRow{
            padding: 0 30px 0 0px;
        }
        .offerBanner {
            background-position: top 0 left -40px;
        }
        .offerBanner1 {
            padding-left: 20px;
        }
        .offerBanner2 {
            padding-left: 220px;
        }
    }
    
    .whyChooseWrapper{
        padding: 40px 50px 150px 50px;
    }

}

@media (max-width: 1199px) {
    .ticketWorld{
        right: 50px;
    }
    .ticketContainer{
        padding-top: 60px;
        padding-left: 25px;
        height: 315px;
        .ticket-text-card {
            min-width: 655px;
            .ticketDetailWrapper {
                margin-top: 5px;
                gap: 10px;
                .ticketDetailDesCol {
                    max-width: 140px;
                }
            }
            .abtUserWrapper {
                margin-top: 12px;
            }
        }
        .boardingWrapper{
            .boardingContainer{
                padding: 20px 0px 0 20px;
                display: flex;
                flex-wrap: wrap;
                width: 245px;
                .getBPassDet{
                    line-height: 26px;
                }
                button{
                    margin-top: 10px;
                }
            }
            
        }
    }
    .servicesFluid{
        .planRow{
            padding: 0;
        }
        .offerBanner1{
            padding-left: 20px;
        }
        .offerBanner2 {
            background-position: top 0 left -130px;
            padding-left: 130px;
            padding-right: 10px;
        }
    }

    .whyChooseWrapper {
        padding: 40px 10px 150px 10px;
        .whyChooseTitle {
            font-size: 20px;
        }
    }

    .contactFluid{
        .contactContainer{
            .contactImg1{
                right: -20px;
            }
            .contactImg2{
                left: 0px;
            }
        }
        .contactDesc {
            padding: 0 230px 0 230px;
        }
    }

    footer{
        .footerContentContainer {
            gap: 10px;
            .footer-list{
                gap: 20px;
            }
        }
        .footer-BG{
            img{
                max-width: 410px;
            }
        }
    } 
    
}


@media (max-width: 991px) {
    header{
        &.sticky{
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            .showToggle {
                top: 70px;
            }
        }
        padding-left: 0 !important;
        padding-right: 0 !important;
        .nav-bar-list {
            display: none;
        }
        .toggleBtn {
            display: block;
            cursor: pointer;
            svg {
                color: var(--white);
            }
        }
        .showToggle {
            position: absolute;
            top: 80px;
            left: 0;
            background: var(--white);
            color: var(--white);
            width: 100%;
            display: block;
            border-top: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
            // box-shadow: 1px 0px 15px rgba(13, 100, 177, 0.6901960784);
            & > div {
                border-bottom: 1px solid #d8d8d8;
                outline: none;
                width: 100%;
                a {
                    padding: 15px 10px;
                    padding-bottom: 15px;
                    display: flex;
                    width: 100%;
                    color: var(--themeColor) !important;
                }
                
            }
            
        }
        
    }
    .common-space{
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .main-heading, .main-heading > *{
        font-size: 35px !important;
        line-height: 45px !important;
    }
    .about-text-card{
        gap: 40px;
        & > div{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            span img{
                max-width: 400px;
            }
            .tStripe::before {
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }

    .ticket-world-img {
        right: 20px;
        width: 90px;
    }
    .ticketContainer{
        padding: 20px;
        background-color: var(--themeColor);
        background-image: url(../images/png/ticketBgRes.png);
        background-position: top 50px left 50%;
        border-radius: 20px;
        height: auto;
        flex-wrap: wrap;
        .ticketWorld{
            right: 50px;
        }
        .ticket-text-card {
            min-width: 100%;
            padding-right: 0;
            .ticketDetailWrapper {
                flex-wrap: wrap;
                margin-top: 5px;
                gap: 10px;
                .ticketDetailCol{
                    max-width: 100%;
                    min-width: 100%;
                }
                .ticketDetailDesCol {
                    display: none;
                }
                .ticketToDetailCol{
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
            // .abtUserWrapper {
            //     flex-wrap: wrap;
            //     gap: 15px;
            //     .abtUserCol{
            //         flex-direction: column;
            //         width: fit-content;
            //     }
            // }
        }
        .boardingWrapper{
            .boardingContainer{
                padding: 30px 0px 0 0px;
                width: 100%;
                .boardingHeading{
                    color: var(--white);
                    line-height: 30px;
                }
                .getBPassDet{
                    color: var(--white);
                }
                button:hover{
                    color: var(--white);
                    border: 1px solid var(--white);
                    svg [fill] {
                        fill: var(--white);
                    }
                }
            }
            
        }
    }
    .servicesFluid{
        padding-top: 20px;
        .offersRow {
            margin-top: 20px;
        }
        .offerBanner {
            background-position: top 0 left 0px;
        }
        .offerBanner2 {
            padding-left: 300px;
        }
        .planRow{
            
            .planWrapper {
                margin-top: 10px;
                margin-bottom: 10px;
                
            }
            .planCol{
                & > :first-child{
                    margin-top: 0;
                }
            }
        }
    }

    .whyChooseWrapper {
        padding: 20px 10px 40px 10px;
        .whyChooseRow {
            margin-top: 25px;
            & > div{
                margin: 20px 0 20px 0;
            }
        }
    }
    
    .expertiseFluid {
        .journeyDesc {
            margin-top: 20px;
            padding: 0 0px 0 0px;
        }
        .serviceRow{
            margin-top: 20px;
            .servicesWrapper{
                height: 200px;
            }
        }
    }

    .contactFluid{
        padding-top: 10px;
        background-position: top 30px left 50%;
        .contactContainer{
            margin-top: 20px;
            .contactImg{
                display: none;
            }
            .contactRow{
                padding-left: 10px;
                padding-right: 10px;
                iframe{
                    height: 300px;
                }
            }
            .mapWrapper{
                padding: 30px 0 20px 0 !important;
            }
            .contactFormWrapper{
                margin-top: 0;
            }
        }
        .contactDesc {
            padding: 0 0px 0 0px;
            margin-top: 30px;
        }
    }

    footer{
        .footer-BG{
            display: none;
        }
    } 
    .banner-bg {
        min-height: 400px;
        max-height: 400px;
    }
    
}

@media (max-width: 767px){
    header{
        .logoHeader{
            svg{
                width: 160px;
            }
        }
    }
    .ticketContainer{
        .ticket-text-card {
            .abtUserWrapper {
                flex-wrap: wrap;
                gap: 15px;
                .abtUserCol{
                    flex-direction: column;
                    width: fit-content;
                }
            }
        }
        
    }
    .servicesFluid{
        .offerBanner {
            background-position: top 0 left -40px;
        }
        .offerBanner2 {
            padding-left: 190px;
            background-position: top 0 left -80px;
        }
    } 

    .whyChooseWrapper {
        padding: 10px 10px 30px 10px;
        .whyChooseRow {
            margin-top: 0px;
            & > div{
                margin: 10px 0 10px 0;
            }
            .whyChooseText {
                font-size: 27px;
                margin-top: 0px;
            }
        }
    }

    footer{
        .footerContentContainer {
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            .footer-list{
                gap: 15px;
            }
            .Footer-social-links{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                .socialLinksContainer{
                    justify-content: center;
                }
                .footer-list{
                    justify-content: center;
                    flex-wrap: wrap;
                }
            }
        }
        .footer-BG{
            display: none;
        }
    } 
}

@media (max-width: 640px){
    .expertiseFluid {
        .planeImg{
            span{
                img{
                    width: 400px;
                }
            }
        }
    }
}


@media (max-width: 575px) {
    .main-heading, .main-heading > *{
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .ticketWorld{
        display: none;
    }
    .servicesFluid{
        .offerBanner {
            background-position: top 0 left 0px;
            background-size: 190% 100%;
            padding-left: 5px;
            padding-right: 5px;
            .offerContentContainer, .offerContentContainer > * {
                max-width: 100%;
                min-width: 100%;
                justify-content: center;
                text-align: center;
            }
        }
        .offerBanner2 {
            background-position: top 0 right 0px;
        }

    } 

    .expertiseFluid {
        .planeImg{
            span{
                img{
                    width: 300px;
                }
            }
        }
    }

    footer{
        .footerContentContainer{
            .footer-list {
                gap: 10px;
            }
        } 
        .container{
            padding-bottom: 80px;
        }
        
    } 
}


@media (max-width: 480px) {
    .headerWhatsapp{
        display: none !important;
    }
    .abtUserColName{
        width: 100% !important;
    }
    
}


@media (max-width: 350px) {
    .servicesFluid{
        button{
            padding-left: 5px !important;
            padding-right: 5px !important;
        }
    } 
    .expertiseFluid {
        .planeImg{
            span{
                img{
                    width: 280px;
                }
            }
        }
    }
}



/**********************RTL*******************/

@media (max-width: 1399px){
    body.rtl {
        .ticketContainer{
            
            .ticket-text-card {
                
                .ticketDetailWrapper{
                    margin-top: 15px;
                    .ticketDetailCol{
                        .txtFromTo, .txtDest, .txtCont, .txtDate {
                            
                        }
                        .txtFromTo {
                            
                        }
                        .txtDest {
                            line-height: 32px;
                        }
                        .txtDate {
                            line-height: 25px;
                        }
                    } 
                    .ticketDetailDesCol{
                        max-width: 250px;
                    }
                    .ticketFromDetailCol{
                        
                    }
                } 
                .abtUserWrapper{
                    margin-top: 35px;
                    .abtUserCol{
                        .abtUserTitle {
                            
                        }
                        .abtUserName{
                            
                        }
                    } 
                } 
            }
            .boardingWrapper{
                .boardingContainer{
                    padding-top: 20px;
                }
            }
            
            .getBPassDet{
                
            }
        }

        .servicesFluid{
            .offersRow{
                .offerBanner1{
                    padding-right: 240px;
                }
            }
            .planRow{
                padding: 0 0px 0 30px;
            }
        }
    }
}


@media (max-width: 1199px){
    body.rtl {
        .ticketContainer{
            
            .ticket-text-card {
                
                .ticketDetailWrapper{
                    margin-top: 10px;
                    .ticketDetailCol{
                        .txtFromTo, .txtDest, .txtCont, .txtDate {
                            
                        }
                        .txtFromTo {
                            line-height: 20px;
                        }
                        .txtDest {
                            line-height: 30px;
                        }
                        .txtDate {
                            line-height: 20px;
                        }
                    } 
                    .ticketDetailDesCol{
                        max-width: 150px;
                    }
                    .ticketFromDetailCol{
                        min-width: 230px;
                    }
                } 
                .abtUserWrapper{
                    margin-top: 20px;
                    .abtUserCol{
                        .abtUserTitle {
                            
                        }
                        .abtUserName{
                            
                        }
                    } 
                } 
            }
            .boardingWrapper{
                .boardingContainer{
                    padding-top: 20px;
                }
            }
            
            .getBPassDet{
                
            }
        }

        .servicesFluid{
            .offersRow{
                .offerBanner1{
                    padding-right: 160px;
                }
                .offerBanner2{
                    background-position: top 0 left -90px;
                    padding-right: 20px;
                }
            }
            .planRow{
                padding: 0 0px 0 0px;
            }
        }
    }
}

@media (max-width: 991px){
    body.rtl {
        .ticketFluid{
            .ticketContainer{
                padding-left: 15px;
                padding-right: 15px;
                background-image: url(../images/png/ticketBgRes.png);
                .ticket-text-card {
                    
                    .ticketDetailWrapper{
                        margin-top: 10px;
                        .ticketDetailCol{
                            .txtFromTo, .txtDest, .txtCont, .txtDate {
                                
                            }
                            .txtFromTo {
                                line-height: 25px;
                            }
                            .txtDest {
                                line-height: 40px;
                            }
                            .txtDate {
                                line-height: 25px;
                            }
                        } 
                        
                        .ticketFromDetailCol{
                            min-width: 100%;
                        }
                    } 
                    .abtUserWrapper{
                        margin-top: 20px;
                        .abtUserCol{
                            .abtUserTitle {
                                
                            }
                            .abtUserName{
                                
                            }
                        } 
                    } 
                }
                .boardingWrapper{
                    .boardingContainer{
                        padding-top: 30px;
                        .boardingHeading{
                            width: 100%;
                        }
                        .getBPassDet{
                            width: 100%;
                        }
                        button {
                            &:hover span{
                                color: var(--white) !important;
                            }
                        } 

                    }
                }
                
                .getBPassDet{
                    
                }
            }
        }
        
        .servicesFluid{
            .offersRow{
                .offerBanner1{
                    padding-right: 350px;
                }
                .offerBanner2{
                    background-position: top 0 left 0px;
                    padding-right: 30px;
                }
            }
        }

        .whyChooseWrapper{
            .whyChooseTitle{
                font-size: 22px;
            }
        }
    }
}

@media (max-width: 767px){
    body.rtl {
        .ticketFluid{
            .ticketContainer{
                
                .ticket-text-card {
                    
                    .ticketDetailWrapper{
                        .ticketDetailCol{
                            .txtFromTo, .txtDest, .txtCont, .txtDate {
                                
                            }
                            .txtFromTo {
                            }
                            .txtDest {
                            }
                            .txtDate {
                            }
                        } 
                        
                        .ticketFromDetailCol{
                        }
                    } 
                    .abtUserWrapper{
                        .abtUserCol{
                            .abtUserTitle {
                                
                            }
                            .abtUserName{
                                
                            }
                        } 
                    } 
                }
                .boardingWrapper{
                    .boardingContainer{
                        button {
                            &:hover span{
                                
                            }
                        }
                        .boardingHeading{
                        }
                        .getBPassDet{
                        }
                            

                    }
                }
                
                .getBPassDet{
                    
                }
            }
        }

        .servicesFluid{
            .offersRow{
                .offerBanner1{
                    padding-right: 200px;
                }
                .offerBanner2{
                    background-position: top 0 left -50px;
                    padding-right: 20px;
                }
            }
        }
        
    }
}


@media (max-width: 575px){
    body.rtl {
        .servicesFluid{
            .offersRow{
                .offerBanner1{
                    background-position: top 0 left 0px;
                    padding-right: 10px;
                    padding-left: 10px;
                }
                .offerBanner2{
                    background-position: top 0 right 0px;
                    padding-right: 10px;
                    padding-left: 10px;
                }
            }
        }
        
    }
}